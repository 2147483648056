import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// react components for routing our app without refresh
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// @material-ui/icons
// core components

import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"

import Parallax from "components/Parallax/Parallax.jsx"
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// import icon from "assets/img/icon-01-01.svg"

// core components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx"
import tabsStyle from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.jsx"
import SEO from "../../components/SEO"
import PreRegistration from "./Sections/PreRegistration"

class Tournament extends React.Component {
  render() {
    const { classes, ...rest } = this.props
    return (
      <div>
        <SEO />
        <Header
          brand="West Coast Beach Volleyball Club"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white",
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/caldyboyle.jpeg")}>
          <div className={classes.container}>
            <GridContainer />
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <PreRegistration />
              </GridItem>
            </GridContainer>
            <br />
            <br />
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default withStyles(tabsStyle)(Tournament)
